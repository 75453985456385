export default function () {

    const checkboxSelector = $('.agent-confirmed-checkbox').find("input[value=true]");

    $('.agent-confirmed-checkbox').on('click', function () {
        let hasPaid = checkboxSelector.is(':checked')
        if (hasPaid) {
            enableNextButton()
        }
        else {
            disableNextButton()
        }
    });

    $('.no-confirmation').on('click', function () {
        checkboxSelector.prop('checked', true)
        $(".agent-confirmed").css('display', 'none')
        enableNextButton()
    });

    $('.require-confirmation').on('click', function () {
        checkboxSelector.prop('checked', false)
        $(".agent-confirmed").css('display', 'block')
        disableNextButton()
    });

    const enableNextButton = () => {
        $('.m-booking-block__actions__menu__item button.form-button').prop('disabled', false)
    }

    const disableNextButton = () => {
        $('.m-booking-block__actions__menu__item button.form-button').prop('disabled', true)
    }
}